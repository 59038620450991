var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "detailDom",
      staticClass: "detailContainer",
      style: { fontSize: _vm.fontSize }
    },
    [
      _vm.calculationCompleteFlag
        ? _c(
            "div",
            {
              ref: "calculationContainer",
              staticClass: "calculationContainer"
            },
            [_vm._v("\n    " + _vm._s(_vm.calculationText) + "\n  ")]
          )
        : _vm._e(),
      _vm.needToShowHtml
        ? _c("div", {
            ref: "richDetailContainer",
            staticClass: "innerContainer htmlContainer",
            domProps: { innerHTML: _vm._s(_vm.richDetail) },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.clickRichDetail.apply(null, arguments)
              }
            }
          })
        : _c(
            "div",
            { staticClass: "innerContainer" },
            [
              _vm._l(_vm.richDetailFromApp, function(item, index) {
                return [
                  item.key === "text"
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "textContainer",
                          style: { maxHeight: _vm.showTotal ? "" : "96px" }
                        },
                        [
                          !_vm.showTotal
                            ? _c("div", {
                                staticClass: "firstLayerMask",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.highlightText(
                                      _vm.searchPageKey,
                                      item.content
                                    )
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("div", {
                            ref: "textInnerItem",
                            refInFor: true,
                            staticClass: "allTextDisplayArea",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.highlightText(
                                  _vm.searchPageKey,
                                  item.content
                                )
                              )
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  item.key === "url" && item.content.showLink
                    ? _c("div", { key: index, style: { marginTop: "15px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "16px",
                                height: "16px",
                                "margin-right": "5px"
                              },
                              attrs: {
                                src:
                                  "https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
                              }
                            }),
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  "word-break": "break-all",
                                  color: "#226CDB",
                                  "font-size": "14px",
                                  "margin-right": "25px",
                                  "text-decoration": "none"
                                },
                                attrs: {
                                  href: item.content.jumpLink
                                    ? item.content.jumpLink
                                    : item.content.showLink,
                                  target: "_blank"
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return (() => {
                                      return
                                    }).apply(null, arguments)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.content.showLink))]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  item.key === "web"
                    ? _c("div", { key: index, style: { marginTop: "15px" } }, [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              width: "100%",
                              height: "110px",
                              background: "#F7F7F7",
                              "border-radius": "8px",
                              position: "relative",
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "flex-start",
                              "box-sizing": "border-box",
                              padding: "0 24px"
                            },
                            attrs: {
                              href: item.content.url || "",
                              target: "_blank"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return (() => {
                                  return
                                }).apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  height: "70px",
                                  display: "flex"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70px",
                                      height: "70px",
                                      overflow: "hidden",
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "center"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "object-fit": "cover"
                                      },
                                      attrs: {
                                        src: _vm.isThePictureAvailable(
                                          item.content.image
                                        )
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-flow": "column",
                                      "align-items": "flex-start",
                                      "justify-content": "flex-start",
                                      height: "70px",
                                      flex: "1",
                                      width: "0",
                                      overflow: "hidden",
                                      "box-sizing": "border-box",
                                      "padding-left": "15px"
                                    }
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: {
                                        width: "100%",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                        "white-space": "nowrap",
                                        "line-height": "20px",
                                        "text-align": "left",
                                        "font-size": "14px",
                                        "font-weight": "bold",
                                        color: "#373737"
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.highlightText(
                                            _vm.searchPageKey,
                                            item.content.title
                                          )
                                        )
                                      }
                                    }),
                                    _c("div", {
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "left",
                                        "font-size": "12px",
                                        color: "#373737",
                                        "margin-top": "9px",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                        display: "-webkit-box",
                                        "-webkit-line-clamp": "2",
                                        "-webkit-box-orient": "vertical"
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.highlightText(
                                            _vm.searchPageKey,
                                            item.content.content
                                          )
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  item.key === "image"
                    ? _c("div", { key: index, style: { marginTop: "15px" } }, [
                        _c("img", {
                          staticClass: "imageStyle",
                          attrs: { src: _vm.formartImageUrl(item.content) }
                        })
                      ])
                    : _vm._e(),
                  item.key === "video"
                    ? _c("div", { key: index, style: { marginTop: "15px" } }, [
                        item.key === "video"
                          ? _c("video", {
                              staticClass: "videoStyle",
                              attrs: {
                                controls: "controls",
                                type: "video/mp4",
                                "webkit-playsinline": "true",
                                playsinline: "true",
                                "x-webkit-airplay": "allow",
                                "x5-video-player-type": "h5",
                                "x5-video-orientation": "portraint",
                                "x5-playsinline": "true",
                                "x5-video-player-fullscreen": "true",
                                src: item.content
                              }
                            })
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              }),
              _c("div", { staticClass: "TextButtonBox" }, [
                _vm.whetherTheHeightExceeds && _vm.isAllText
                  ? _c(
                      "div",
                      {
                        staticClass: "changeStatusButton",
                        style: {
                          transform: _vm.showTotal
                            ? "rotate(0deg)"
                            : "rotate(180deg)"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeExchangeStatus.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-up iconStyle"
                        })
                      ]
                    )
                  : _vm._e(),
                !_vm.isAllText &&
                _vm.richDetailFromApp.length < _vm.dataFromAppLength
                  ? _c(
                      "div",
                      {
                        staticClass: "changeStatusButton",
                        style: {
                          transform: "rotate(180deg)"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.clickRichDetail.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-up iconStyle"
                        })
                      ]
                    )
                  : _vm._e()
              ])
            ],
            2
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }