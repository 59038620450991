<template>
  <div class="detailsHeader">
    <div class="avatarBox">
      <Avatar
        :path="headerData.profilePhoto"
        :international="headerData.country"
        :providerId="headerData.providerId"
      >
      </Avatar>
    </div>
    <div class="postInfoBox">
      <div class="authorOfThePost">
        <span
          @click.stop="jumpToPage"
          :class="vipStatus ? 'vipName' : ''"
          :title="headerData.name"
        >
          {{
            headerData.name && headerData.name.length > 10
              ? headerData.name.slice(0, 10) + ".."
              : headerData.name || headerData.providerId
          }}
        </span>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('paidMember')"
          placement="bottom"
          popper-class="me-tooltip-effect"
        >
          <VipIcon :vipStatus="vipStatus" :id="headerData.providerId" />
        </el-tooltip>
      </div>
      <div class="infoOfpost">
        {{ headerData.publishTime }}
        <div
          class="viewsBlock"
          v-if="headerData.pageViewCount != 0 && showViewCount"
        >
          <span class="iconfont_Me icon-Views iconStyle"></span>
          <span>{{ handleNewVersionCount(headerData.pageViewCount) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VipIcon from "../icons/IconVip.vue";
import Avatar from "../avatarDisplay";
export default {
  components: {
    Avatar,
    VipIcon,
  },
  props: {
    // 展示数据内容
    headerData: {
      type: Object,
      default: () => {
        return {
          name: "",
          country: 0,
          profilePhoto: "",
          publishTime: "",
          providerId: 0,
          pageViewCount: 0,
        };
      },
    },
    vipStatus: {
      type: Boolean,
      default: false,
    },
    showViewCount: {
      type: Boolean,
      default: true,
    },
    isVip: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    jumpToPage() {
      this.$store.commit("setScrollTopInstance", 0);
      this.$store.commit("setPersonalInfoIndex", 0);
      this.routeJump({
        name: "personalCenter",
        params: { id: this.headerData.providerId },
      });
      // 跳转过后关闭多媒体弹窗
      this.$nextTick(() => {
        this.$store.commit("setVideoRecording", []);
        this.$store.dispatch("commitClosemultimediaPopup");
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.detailsHeader
  width 100%;
  height 50px;
  display flex;
  align-items center;
  justify-content flex-start;
  .avatarBox
    width 50px;
    height 50px;
    flex-shrink 0;
  .postInfoBox
    flex 1;
    height 50px;
    min-width 0;
    padding-left 14px;
    box-sizing border-box;
    overflow hidden;
    display flex;
    flex-flow column;
    align-items flex-start;
    justify-content space-around;
    .authorOfThePost
      width 100%;
      color #373737;
      font-size 14px;
      font-weight bold;
      overflow hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center
    .infoOfpost
      width 100%;
      color #8F8F8F;
      font-size 12px;
      display flex;
      align-items center;
      justify-content space-between;
      overflow hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      .viewsBlock
        display flex;
        align-items center;
        justify-content flex-start;
        .iconStyle
          color #8F8F8F;
          font-size 20px;
          margin 0 2px 0 0;
</style>
