<template>
  <div class="detailContainer" ref="detailDom" :style="{ fontSize: fontSize }">
    <!-- 这个DIV主要是用于计算文本内容行数的盒子，算完就会删除 -->
    <div
      class="calculationContainer"
      ref="calculationContainer"
      v-if="calculationCompleteFlag"
    >
      {{ calculationText }}
    </div>
    <div
      class="innerContainer htmlContainer"
      ref="richDetailContainer"
      v-if="needToShowHtml"
      v-html="richDetail"
      @click.stop="clickRichDetail"
    ></div>
    <div class="innerContainer" v-else>
      <template v-for="(item, index) in richDetailFromApp">
        <div
          class="textContainer"
          v-if="item.key === 'text'"
          :key="index"
          :style="{ maxHeight: showTotal ? '' : '96px' }"
        >
          <!-- 第一层覆盖面只显示三行文本 -->
          <div
            v-if="!showTotal"
            class="firstLayerMask"
            v-html="highlightText(searchPageKey, item.content)"
          ></div>
          <!-- 展示全部文本用的盒子 -->
          <div
            class="allTextDisplayArea"
            ref="textInnerItem"
            v-html="highlightText(searchPageKey, item.content)"
          ></div>
        </div>

        <div
          v-if="item.key === 'url' && item.content.showLink"
          :key="index"
          :style="{ marginTop: '15px' }"
        >
          <div
            style="display:flex;align-items:center;justify-content flex-start;"
          >
            <img
              style="width:16px;height:16px;margin-right:5px;"
              src="https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
            />
            <a
              @click.stop="
                () => {
                  return;
                }
              "
              :href="
                item.content.jumpLink
                  ? item.content.jumpLink
                  : item.content.showLink
              "
              target="_blank"
              style="word-break: break-all;color:#226CDB;font-size:14px;margin-right:25px;text-decoration:none;"
              >{{ item.content.showLink }}</a
            >
          </div>
        </div>
        <div
          v-if="item.key === 'web'"
          :key="index"
          :style="{ marginTop: '15px' }"
        >
          <a
            @click.stop="
              () => {
                return;
              }
            "
            :href="item.content.url || ''"
            target="_blank"
            style="width:100%;height:110px;background:#F7F7F7;border-radius:8px;position:relative;display:flex;align-items:center;justify-content:flex-start;box-sizing:border-box;padding:0 24px;"
          >
            <div style="width:100%;height:70px;display:flex;">
              <div
                style="width:70px;height:70px;overflow:hidden;display:flex;align-items:center;justify-content:center"
              >
                <img
                  style="width:100%;height:100%;object-fit:cover"
                  :src="isThePictureAvailable(item.content.image)"
                />
              </div>
              <div
                style="display:flex;flex-flow:column;align-items:flex-start;justify-content:flex-start;height:70px;flex:1;width: 0;overflow:hidden;box-sizing:border-box;padding-left: 15px;"
              >
                <div
                  style="width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;line-height:20px;text-align:left;font-size:14px;font-weight:bold;color:#373737"
                  v-html="highlightText(searchPageKey, item.content.title)"
                ></div>
                <div
                  style="width:100%;text-align:left;font-size:12px;color:#373737;margin-top:9px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;"
                  v-html="highlightText(searchPageKey, item.content.content)"
                ></div>
              </div>
            </div>
          </a>
        </div>
        <div
          v-if="item.key === 'image'"
          :key="index"
          :style="{ marginTop: '15px' }"
        >
          <img class="imageStyle" :src="formartImageUrl(item.content)" />
        </div>
        <div
          v-if="item.key === 'video'"
          :key="index"
          :style="{ marginTop: '15px' }"
        >
          <video
            class="videoStyle"
            controls="controls"
            type="video/mp4"
            v-if="item.key === 'video'"
            webkit-playsinline="true"
            playsinline="true"
            x-webkit-airplay="allow"
            x5-video-player-type="h5"
            x5-video-orientation="portraint"
            x5-playsinline="true"
            x5-video-player-fullscreen="true"
            :src="item.content"
          ></video>
        </div>
      </template>
      <div class="TextButtonBox">
        <div
          class="changeStatusButton"
          @click.stop="changeExchangeStatus"
          v-if="whetherTheHeightExceeds && isAllText"
          :style="{
            transform: showTotal ? 'rotate(0deg)' : 'rotate(180deg)',
          }"
        >
          <span class="iconfont_Me icon-up iconStyle"></span>
        </div>
        <div
          class="changeStatusButton"
          @click.stop="clickRichDetail"
          v-if="!isAllText && richDetailFromApp.length < dataFromAppLength"
          :style="{
            transform: 'rotate(180deg)',
          }"
        >
          <span class="iconfont_Me icon-up iconStyle"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imagesloaded from "imagesloaded";
export default {
  data() {
    return {
      // 是否展示所有文本内容(只对文本内容生效)
      showTotal: false,
      // 内容高度是否超出父元素高度，可以开始滚动
      whetherTheHeightExceeds: false,
      // 计算文本内容行数高度的盒子是否展示
      calculationCompleteFlag: true,
      // 需要计算的行数高度的文本内容
      calculationText: "",
      // 新版本帖子数组数据结构
      richDetailFromApp: [],
      // 判断是不是
      isAllText: false,
      // 数组数据长度
      dataFromAppLength: 0,
    };
  },
  props: {
    // 富文本数组结构或者老数据
    postDetail: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "14px",
    },
    searchPageKey: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // 这里用于判断新版帖子文本内容展示
    let postDetail = this.postDetail;
    let richDetailFromApp = [];
    if (
      typeof postDetail === "string" &&
      postDetail !== "" &&
      postDetail.indexOf("[") === 0 &&
      postDetail.lastIndexOf("]") === postDetail.length - 1
    ) {
      // 2021.6.24 处理外国人人名后单引号被转换成双引号解析错误的问题，先屏蔽
      // try {
      // postDetail = postDetail.replace(/'/g, '"')
      // } catch (error) {
      //   console.log(postDetail);
      // }
      try {
        let dataFromApp = JSON.parse(postDetail);
        this.dataFromAppLength = dataFromApp.length;
        dataFromApp.forEach((element, index) => {
          // 这里主要是为了获取帖子里的所有文本内容，判断文本内容的高度和行数
          if (
            element.key == "text" &&
            element.content.replace(/ /g, "") !== "" &&
            element.content.replace("\n", "") !== " "
          ) {
            this.calculationText =
              this.calculationText + element.content.trim();
            if (index !== dataFromApp.length - 1) {
              this.calculationText = this.calculationText + "\n";
            }
          }
        });
        this.$nextTick(() => {
          // 获取行数
          let lineNumber = Math.round(
            this.$refs.calculationContainer.offsetHeight / 24
          );
          // 计算完了就删除这个元素
          this.calculationCompleteFlag = false;
          this.isAllText = false;
          // 如果文本总行数小于5行，则按顺序展示内容
          if (lineNumber <= 5) {
            let numberOfRemainingRows = 5;
            for (let index = 0; index < dataFromApp.length; index++) {
              let element = dataFromApp[index];
              if (numberOfRemainingRows === 0) {
                break;
              }
              // 先装填文本
              if (
                element.key == "text" &&
                element.content.replace(/ /g, "") !== "" &&
                element.content.replace("\n", "") !== " "
              ) {
                numberOfRemainingRows--;
                richDetailFromApp.push({
                  key: "text",
                  content: element.content,
                });
              }
              // 再装填超链接，同一个超链接的文本和预览加在一起算一行，
              if (element.key === "url" && element.content !== "") {
                numberOfRemainingRows--;
                // 用于处理字符串JSON
                try {
                  element.content = JSON.parse(element.content);
                } catch (error) {}
                richDetailFromApp.push(element);
                // 判断它的下一个是不是对应它的超链接
                try {
                  if (dataFromApp[index + 1]) {
                    let nextItem = dataFromApp[index + 1];
                    if (
                      nextItem.key === "web" &&
                      nextItem.content.url === element.content.jumpLink
                    ) {
                      richDetailFromApp.push(nextItem);
                    }
                  }
                } catch (error) {}
              }
              // 如果没有超链接文本，只有预览的话，那预览也算一行
              if (element.key === "web") {
                // 如果帖子第一个数据就是预览或者说预览的前一个数据不是链接文本或者前一个是链接文本但是不是这个预览对应的链接文本，则直接装填，并算成一行
                if (
                  index === 0 ||
                  dataFromApp[index - 1].key !== "url" ||
                  (dataFromApp[index - 1].key === "url" &&
                    element.content.url !==
                      dataFromApp[index - 1].content.jumpLink)
                ) {
                  numberOfRemainingRows--;
                  richDetailFromApp.push(element);
                }
              }
            }
          }
          if (this.calculationText !== "" && richDetailFromApp.length === 0) {
            this.isAllText = true;
            richDetailFromApp.push({
              key: "text",
              content: this.calculationText,
            });
          }
          if (richDetailFromApp.length === 0) {
            for (let i = 0; i < dataFromApp.length; i++) {
              let item = dataFromApp[i];
              // 将图片或视频数据装入数组，只展示一个
              if (item.key === "image" || item.key === "video") {
                richDetailFromApp.push(item);
                break;
              }
            }
          }
          this.richDetailFromApp = richDetailFromApp;
          this.$forceUpdate();
        });
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    // 帖子富文本内容数据
    richDetail() {
      let richDetail = "";
      // 判断是不是以前的老数据，如果的是的话则将设置为HTML字符串
      if (typeof this.postDetail === "string" && this.postDetail !== "") {
        if (
          this.postDetail.indexOf("[") !== 0 ||
          this.postDetail.lastIndexOf("]") !== this.postDetail.length - 1
        ) {
          richDetail = this.postDetail;
          richDetail = richDetail.replace(/^\s+/, "");
        }
      }
      if (richDetail != "") {
        richDetail = this.highlightText(this.searchPageKey, richDetail);
      }
      return richDetail;
    },
    // 是显示HTML富文本还是显示数组富文本,优先展示数组数据
    needToShowHtml() {
      return (
        this.richDetailFromApp.length === 0 ||
        (this.richDetailFromApp.length !== 0 && this.richDetail != "")
      );
    },
  },
  methods: {
    // 点击富文本跳转详情
    clickRichDetail() {
      this.$emit("jumpToPage");
    },
    // 判断图片是否可用
    isThePictureAvailable(image) {
      return image && image != "" && image.indexOf("http") != -1
        ? image
        : "https://tfile.melinked.com/2021/04/0b5a6b1f-acd0-488d-9fdc-a0cdd83220d1.png";
    },
    // 改变文本展示状态
    changeExchangeStatus() {
      // 如果内容高度超出容器高度，则直接跳转到帖子详情
      if (
        (this.$refs.textInnerItem &&
          this.$refs.textInnerItem[0].offsetHeight > 240) ||
        (this.$refs.richDetailContainer &&
          this.$refs.richDetailContainer.offsetHeight > 240)
      ) {
        this.$emit("jumpToPage");
        return;
      }
      this.showTotal = !this.showTotal;
      this.$nextTick(() => {
        this.$emit("resetLayout");
      });
    },
    // 用于监听容器中图片是否加载成功，如果成功则判断一下内容和容器的高度
    imageLoadSuccess() {
      imagesloaded(".innerContainer", () => {
        try {
          if (this.needToShowHtml) {
            this.whetherTheHeightExceeds =
              this.$refs.detailDom.offsetHeight <
              this.$refs.richDetailContainer.offsetHeight;
          } else {
            // 判断文本容器高度是否大于容器高度，如果是的话展示展开按钮
            if (this.$refs.textInnerItem) {
              this.whetherTheHeightExceeds =
                this.$refs.detailDom.offsetHeight <
                this.$refs.textInnerItem[0].offsetHeight;
            }
          }
        } catch (error) {}
      });
    },
  },
  watch: {
    // 监听数组数据帖子信息开始渲染完成
    postDetail: {
      handler() {
        this.$nextTick(() => {
          try {
            // 这里的判断只能判断出数组数据加载成功后的高度，如果图片还未加载出来的话则检测不出来内容高度，所以需要专门监听图片加载进度
            if (this.needToShowHtml) {
              this.whetherTheHeightExceeds =
                this.$refs.detailDom.offsetHeight <
                this.$refs.richDetailContainer.offsetHeight;
            } else {
              // 判断文本容器高度是否大于容器高度，如果是的话展示展开按钮
              if (this.$refs.textInnerItem) {
                this.whetherTheHeightExceeds =
                  this.$refs.detailDom.offsetHeight <
                  this.$refs.textInnerItem[0].offsetHeight;
              }
            }
          } catch (error) {}
          this.imageLoadSuccess();
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="stylus" scoped>
.detailContainer
  width 100%;
  margin-top 3px;
  line-height 20px;
  position relative;
  cursor pointer;
  user-select none;
  color #333333;
  box-sizing border-box;
  overflow hidden;
  .htmlContainer
    max-height 240px;
    overflow hidden;
    display: -webkit-box;
    white-space: pre-wrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
  .calculationContainer
    width 100%;
    overflow hidden;
    position relative;
    line-height 24px;
    word-break: break-all;
    white-space: pre-wrap;
    top 0;
    left 0;
    z-index 1;
    opacity 0;
    position absolute;
  .innerContainer
    width 100%;
    min-height 0;
    overflow hidden;
    position relative;
    .TextButtonBox
      right 0;
      bottom 6px;
      width 70px;
      height 14px;
      position absolute;
      background-image: linear-gradient(to right, rgba(255,255,255,0.2) , rgba(255,255,255,1), rgba(255,255,255,1));
      z-index: 2;
      .changeStatusButton
        width 14px;
        height 14px;
        float: right;
        margin-right: 10px;
        cursor pointer;
        z-index 2;
        border-radius 50%;
        background #CCCCCC;
        transition transform .1s;
        display flex;
        text-indent:0;
        align-items center;
        justify-content center;
        .iconStyle
          color #ffffff;
          font-size 12px
    .imageStyle
      width 300px;
      height 225px;
      object-fit contain;
      border-radius 6px;
    .videoStyle
      width 355.8px;
      height 200px;
    .textContainer
      width 100%;
      position relative;
      z-index 2;
      overflow hidden;
      position relative;
      line-height 24px;
      word-break: break-all;
      .firstLayerMask
        display: block;
        white-space: pre-wrap;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        max-height 72px;
        width: 100%;
        overflow: hidden;
        color: #000;
        background-color: #fff;
        text-indent: 0;
      .allTextDisplayArea
        width 100%;
        white-space: pre-wrap;
</style>
<style lang="stylus">
.detailContainer
  .htmlContainer
    img
      max-width 100% !important;
    video
      max-width 100% !important;
    p,span
      font-size 14px !important;
    ol,ul
      margin auto;
      padding 20px;
    ol
      list-style-type decimal;
    ul
      list-style-type disc;
</style>
