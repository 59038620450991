var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detailsHeader" }, [
    _c(
      "div",
      { staticClass: "avatarBox" },
      [
        _c("Avatar", {
          attrs: {
            path: _vm.headerData.profilePhoto,
            international: _vm.headerData.country,
            providerId: _vm.headerData.providerId
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "postInfoBox" }, [
      _c(
        "div",
        { staticClass: "authorOfThePost" },
        [
          _c(
            "span",
            {
              class: _vm.vipStatus ? "vipName" : "",
              attrs: { title: _vm.headerData.name },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.jumpToPage.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.headerData.name && _vm.headerData.name.length > 10
                      ? _vm.headerData.name.slice(0, 10) + ".."
                      : _vm.headerData.name || _vm.headerData.providerId
                  ) +
                  "\n      "
              )
            ]
          ),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.$t("paidMember"),
                placement: "bottom",
                "popper-class": "me-tooltip-effect"
              }
            },
            [
              _c("VipIcon", {
                attrs: {
                  vipStatus: _vm.vipStatus,
                  id: _vm.headerData.providerId
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "infoOfpost" }, [
        _vm._v("\n      " + _vm._s(_vm.headerData.publishTime) + "\n      "),
        _vm.headerData.pageViewCount != 0 && _vm.showViewCount
          ? _c("div", { staticClass: "viewsBlock" }, [
              _c("span", { staticClass: "iconfont_Me icon-Views iconStyle" }),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.handleNewVersionCount(_vm.headerData.pageViewCount)
                  )
                )
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }